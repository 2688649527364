import React from 'react'

const StatRow = (props) => {

        
        return (
            <div className="stat-row-wrapper" role="rowgroup">
                <div className="stat-row" role="row">
                  <div className="left-row-column">
                    <div className="title-cell" role="gridcell">{props.firstKey}</div>
                    <div className="right-cell stat-value" role="gridcell">{props.firstValue}</div>
                  </div>
                  <div className="right-row-column">
                    <div className="title-cell" role="gridcell">{props.secondKey}</div>
                    <div className="stat-value" role="gridcell">{props.secondValue}</div>
                    </div>
                </div>
            </div>
        )
}

export default StatRow
