import React from 'react'

const Box = (props) => {

        
        return (
            <div className="stat-summary-box">
                <div className="stat-summary-box-title">
                {props.title}
                </div>
                <div className="stat-summary-box-content">
                {props.value}
                </div>
            </div>
        )
}

export default Box
